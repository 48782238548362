@charset 'UTF-8';

#wrap {
  @include SP {
    padding-top: 66px;
  }
}

.cm-sticky {
  @include PC {
    position: fixed;
    left: 18px;
    bottom: 15.2%;
    z-index: 1002;
    width: 26px;
  }
  .txt {
    @include ffRu;
    @include font(12,12,0,600);
    white-space: nowrap;
    transform-origin: top left;
    display: inline-block;
    width: 115px;
    @include transform(translateX(10px) rotate(-90deg));
  }
  .list-icn {
    li {
      margin-top: 10px;
      text-align: center;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  @include SP {
    .list-icn {
      text-align: center;
      li {
        display: inline-block;vertical-align: middle;
        margin-top: 0;
        text-align: center;
        margin: 0 8px;
        img {
          width: 28px;
        }
        &:first-child {
          margin-top: 0;
        }
        &.icn_twitter {
          img {
            height: 25px;
          }
        }
      }
    }
  }
}
.header {
  position: relative;
  left: auto;
  top: auto;
  z-index: 1000;
  width: 100%;
  padding: 30px 80px;
  background: #fff;
  @include PC {
    @include max-screen(960px) {
      padding: 25px 40px;
    }
  }
  .inHeader {
    position: relative;
    z-index: 2000;
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    width: 100%;
  }
  #logo {
    padding-top: 6px;
    @include add_prefix(transition, 0.3s ease all);
    a {
      display: inline-block;
    }
    img {
      display: block;
      @include add_prefix(transition, 0.3s ease all);
    }
    @include PC {
      @include max-screen(960px) {
        width: 146px;
      }
    }
  }
  .h-right {
    width: calc(100% - 195px);
    @include PC {
      @include max-screen(960px) {
        width: calc(100% - 165px);
      }
    }
  }
  .hNavi {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    .btn-login {
      width: 194px;
      background: $orange01;
      padding: 12px 10px;
      @include ffRu;
      @include font(13,15,200,bold);
      color: #fff;
      text-align: center;
      margin-left: 25px;
    }
  }
  @include SP {
    padding: 9px 15px 0 15px;
    height: 66px;
    position: fixed;
    background: none;
    &:after {
      position: absolute; content: "";
      left: 0; top: 0; right: 0; height: 100%;
      -webkit-backdrop-filter: blur(23px);
      backdrop-filter: blur(23px);
      background-color: rgba(255,255,255,0.49);
      @include add_prefix(transition, 0.3s ease all);
    }
    .inHeader {
      position: relative;
      z-index: 2000;
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      width: 100%;
    }
    #logo {
      padding-top: 9px;
      width: 100px;
      z-index: 10009;
      position: relative;
    }
    .h-right {
      width: calc(100% - 120px);
      padding-right: 60px;
    }
    .hNavi {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(flex-end);
      margin-top: 6px;
      .btn-login {
        width: 140px;
        padding: 13px 10px;
        @include font(9,10);
        margin-left: 0;
      }
    }
  }
}

.layerMenu {
  position: relative;
  .gNavi {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    margin: 29px 4px 0 0;
    @include PC {
      @include max-screen(960px) {
        margin-top: 20px;
      }
    }
    > li {
      vertical-align: top;
      width: auto;
      margin-right: 6%;
      font-weight: bold;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      > a {
        display: block;
        position: relative;
        @include ffBun;
        @include font(16,22,100,400);
        &:after {
          position: absolute; content: "";
          left: 50%; top: -13px;
          margin-left: -25px;
          text-align: center;
          width: 49px; height: 49px;
          background: $yellow02;
          @include border-radius(50%);
          @include transform(scale(0));
          @include add_prefix(transition, 0.3s ease all);
        }
        .img {
          margin-right: 4px;
          position: relative;
          z-index: 19;
        }
        .txt {
          position: relative;
          z-index: 19;
        }
        &.is-active {
          opacity: 1;
          &:after {
            @include transform(scale(1));
          }
        }
        @include HOVER {
          &:hover {
            opacity: 1;
            &:after {
              @include transform(scale(1));
            }
          }
        }
      }
    }
  }
  @include SP {
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 1000;
    height: 100%;
    text-align: left;
    -webkit-backdrop-filter: blur(23px);
    backdrop-filter: blur(23px);
    background-color: rgba(255,255,255,0.49);
    padding: 62px 0 0;
    opacity: 0;
    visibility: hidden;
    @include add_prefix(transition, 0.3s ease all);
    .inLayer {
      position: relative;
      height: 100%;
      padding: 18px 35px 80px;
      overflow: auto;
    }
    .cm-box-search{
      width: 100%;
      input{
        border: 1px solid #D9D9D9;
        background-color: #fff;
        padding-left: 40px;
        &::-webkit-input-placeholder { /* Edge */
          color: #B5A7A7;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #B5A7A7;
        }


      }
    }
    .cm-news-pickupTag{
      margin-top: 0;
      .cm-news-taglist{
        margin-top: 5px;
      }
    }
    .news{
      .newsCat{
        @include SP{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 20px 0;
          >li{
            width: calc((100% - 3px)/2);
            margin-top: 3px;
            a{
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 33px;
              background-color: rgba(#fff, .4);
              padding-left: 10px;
              @include ffRu;
              @include font-size(11);
              @include letter-spacing(150);
              font-weight: bold;
              img{
                position: absolute;
                top: 50%;
                left: 7px;
                transform: translateY(-50%) scale(0.75);
              }
              .c-arr{
                position: absolute;
                top: 50%;
                right: 10px;
                width: 8px;
                height: 3px;
                margin-top: -2px;
                &::before{
                  width: 8px;
                }
                &::after{
                  width: 4px;
                }
              }
            }
            &.c-diet{
                a{
                    color: $color_diet;
                }
            }
            &.c-fitness{
                a{
                    color: $color_fitness;
                }
            }
            &.c-healthcare{
                a{
                    color: $color_healthcare;
                }
            }
            &.c-beauty{
                a{
                    color: $color_beauty;
                }
            }
            &.c-lifestyle{
                a{
                    color: $color_lifestyle;
                }
            }
          }
        }
      }
    }
    .btn-login {
      width: 250px;
      display: block;
      background: $orange01;
      padding: 17px 10px;
      @include ffRu;
      @include font(12,14,200,bold);
      color: #fff;
      text-align: center;
      margin: 25px auto 35px;
    }
    .gNavi {
      margin: 15px 0 0 0;
      border-bottom: 1px solid #fff;
      > li {
        display: block;
        width: 100%;
        font-weight: bold;
        text-align: center;
        margin: 0;
        > a {
          border-top: 1px solid #fff;
          position: relative;
          color: #000000;
          padding: 16px 0 15px 0;
          &:after {
            display: none;
          }
          &:before {
            position: absolute; content: "";
            right: 16px;
            top: 50%;
            width: 0;
            height: 0;
            margin-top: -5px;
            border-bottom: 3px solid transparent;
            border-left: 7px solid #F86852;;
            border-top: 3px solid transparent;
          }
        }
      }
    }
    &.menu_opened {
      opacity: 1;
      visibility: inherit;
    }
  }
}

.hamberger {
  position: fixed;
  right: 0;
  top: 7px;
  z-index: 10008;
  width: 55px;
  height: 50px;
  padding: 17px 13px 0;
  line-height: 1;
  font-size: 0;
  text-align: center;
  cursor: pointer;
  display: none;
  span {
    display: inline-block;
    width: 25px;
    height: 1px;
    margin-bottom: 7px;
    background: #000000;
    @include add_prefix(transition, 0.3s ease all);
  }
  &.is-active {
    padding: 16px 13px 0;
    right: 4px;
    span {
      width: 27px;
    }
    .ham { @include transform_c(rotate(45deg) translateY(6px) translateX(6px)); }
    .ber { @include transform_c(rotate(-45deg)); }
    .ger { display: none; }
  }
  @include max-screen(767px) {
    display: block;
  }
}

@charset 'UTF-8';

.text-nowrap {
  white-space: nowrap;
}
.js-image,
.js-switch-image {
  display: block;
  background: #fff top center no-repeat;
  background-size: cover;
  overflow: hidden;
  backface-visibility: hidden;
  img {
    position: absolute;
    left: -9999px;
    backface-visibility: hidden;
  }
}

.comming {
  text-align: center;
  padding: 20px 0;
  width: 100%;
  @include font(15,32,50,500);
  @include SP {
    padding: 20px 0 0 0;
  }
}

.nobreak-ip5 {
  @include IP5 {
    display: none;
  }
}

#wrap {
  overflow: hidden;
  position: relative;
}

.tel-sp {
  @include PC {
    pointer-events: none;
    cursor: default;
  }
}

.pc-block-flex {
  @include PC {
    @include flexbox;
    @include flex-wrap(wrap);
  }
}
.c-block-flex {
  @include flexbox;
  @include flex-wrap(wrap);
}
/*-----------------------------------------------------------
BACKGROUND
-------------------------------------------------------------*/

.accordion-box {
  display: none;
}

/*-----------------------------------------------------------
TITLE
-------------------------------------------------------------*/
.cm-ttl01 {
  text-align: center;
  .ttl-en {
    @include ffBun;
    @include font(58,82,50,500);
    @include PC {
      @include max-screen(980px) {
        @include font(50,70);
      }
    }
  }
  .ttl-ja {
    display: block;
    @include font(16,24,150,bold);
    margin-top: 4px;
  }
  .img {
    display: inline-block;vertical-align: middle;
    line-height: 1;
    margin: 5px 15px 0 0;
    @include PC {
      @include max-screen(980px) {
        margin-right: 10px;
        img {
          height: 37px;
        }
      }
    }
  }
  &.smaller01 {
    .ttl-en {
      @include font(50,70,100);
      @include PC {
        @include max-screen(980px) {
          @include font(44,60);
        }
      }
    }
    .ttl-ja {
      margin-top: 3px;
    }
  }
  @include SP {
    .ttl-en {
      @include font(39,50,50,500);
    }
    .ttl-ja {
      @include font(14,20);
      margin-top: 6px;
    }
    .img {
      margin: 2px 10px 0 0;
      img {
        max-height: 35px;
      }
    }
    &.smaller01 {
      .ttl-en {
        @include font(30,42);
      }
      .ttl-ja {
        margin-top: 3px;
      }
    }
  }
}

.cm-ttl02-cover {
  text-align: center;
}
.cm-ttl02 {
  position: relative;
  padding: 11px 0 0 40px;
  min-height: 86px;
  &:after {
    position: absolute; content: "";
    left: 0; top: 0;
    text-align: center;
    width: 86px; height: 86px;
    background: $yellow02;
    @include border-radius(50%);
  }
  .ttl-en {
    @include ffBun;
    @include font(36,43,150,400);
    color: $orange01;
    position: relative;
    z-index: 29;
  }
  .ttl-ja {
    display: block;
    @include font(14,20,150,900);
    margin: 0 0 0 2px;
    position: relative;
    z-index: 29;
  }
  &.is-center {
    text-align: center;
    padding: 8px 44px 0;
    display: inline-block;
    &:after {

    }
    .ttl-en {
    }
    .ttl-ja {
      margin: 0 0 6px;
    }
  }
  @include SP {
    padding: 7px 0 0 23px;
    min-height: 52px;
    &:after {
      width: 50px; height: 50px;
    }
    .ttl-en {
      @include font(24,32);
    }
    .ttl-ja {
      @include font(10,15);
      margin: 0 0 0 2px;
    }
    &.is-center {
      padding: 0 20px 0;
      .ttl-en {
      }
      .ttl-ja {
        margin: 0;
      }
    }
  }
}
/*-----------------------------------------------------------
BUTTON
-------------------------------------------------------------*/
.c-arr {
  position: relative;
  display: inline-block;
  width: 20px; height: 6px;
  @include add_prefix(transition, 0.3s ease all);
  &:before, &:after {
    position: absolute; content: "";
    left: 0; bottom: 0; height: 1px; width: 100%;
    background: $dark01;
  }
  &:after {
    width: 100%;
    left: auto; right: 0; width: 9px;
    @include transform(rotate(35deg));
    transform-origin: bottom right;
  }
  @include SP {
    width: 15px; height: 6px;
    &:after {
      width: 7px;
    }
  }
}

.cm-btn01 {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 363px;
  overflow: hidden;
  padding: 14px 10px 16px;
  border: 2px solid $black;
  @include border-radius(40px);
  .c-arr {
    z-index: 29; position: absolute;
    right: 24px; top: 50%;
    margin-top: -4px;
    width: 18px;
  }
  .txt {
    @include font(16,19,100,900);
    position: relative;
    z-index: 19;
  }
  .txt-ja {
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
  }
  .txt-en {
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    @include ffBun;
    @include font(18,18,50,400);
  }
  .txt-en02 {
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    @include ffRu;
    @include font(16,19,100,700);
  }
  .txt-img {
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    margin: -2px 8px 0px 0;
  }
  &.is-green {
    border: 0;
    background: $green01;
    max-width: 433px;
    padding: 23px 10px 25px;
    .c-arr {
      right: 32px;
      width: 20px;
    }
    .txt {
      color: #fff;
    }
    .txt {
      @include font(19,28,150,900);
    }
    .txt-en {
      @include font(29,29,50,bold);
      @include PC {
        @include max-screen(980px) {
          @include font(25,25);
        }
      }
    }
    .txt-img {
      margin: -4px 14px 0px 0;
    }
    .c-arr {
      &:before, &:after {
        background: #fff;
      }
    }
  }
  @include HOVER {
    &:hover {
      .c-arr {
        @include transform(translateX(8px));
      }
    }
  }
  @include SP {
    max-width: 285px;
    padding: 7px 8px 11px;
    .c-arr {
      right: 15px;
      margin-top: -5px;
      width: 16px;
    }
    .txt {
      @include font(14,17);
    }
    .txt-ja {
    }
    .txt-en {
      @include font(19,19);
      margin-right: 4px;
    }
    .txt-en02 {
      @include font(14,17);
    }
    .txt-img {
      display: inline-block;
      vertical-align: middle;
      font-style: normal;
      margin: -2px 8px 0px 0;
    }
    &.is-green {
      max-width: 285px;
      padding: 9px 10px 12px;
      .c-arr {
        right: 20px;
        width: 13px;
      }
      .txt {
        @include font(10,15);
      }
      .txt-en {
        @include font(18,20);
        margin-right: 6px;
      }
      .txt-ja {
        margin-bottom: 4px;
      }
      .txt-img {
        margin: -2px 8px 0px 0;
        img {
          max-height: 15px;
        }
      }
    }
  }
}

.cm-btn02 {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 211px;
  overflow: hidden;
  padding: 10px 10px 10px;
  background: $yellow02;
  @include border-radius(20px);
  @include ffRu;
  @include font(12,14,200,600);
  @include HOVER {
    &:hover {
      .c-arr {
        @include transform(translateX(8px));
      }
    }
  }
}

/*-----------------------------------------------------------
HOVER
-------------------------------------------------------------*/


/*-----------------------------------------------------------
COMMON SUBPAGE
-------------------------------------------------------------*/
// breadcrumb
.breadcrumb {
  margin-top: 14px;
  ul {
    line-height: 1.4;
    li {
      display: inline;
      @include font-size(12);
      @include letter-spacing(0);
      position: relative;
      margin-right: 5px;
      padding-right: 23px;
      color: #404040;
      &:before {
        position: absolute;
        content: ">";
        right: 0;
        top: 9%;
        width: 10px;
        @include font-size(12);
        color: #404040;
      }
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        &:before {
          display: none;
          background: none;
        }
      }
    }
    a {
      display: inline-block;
      color: #404040;
    }
  }
  @include SP {
    margin-top: 6px;
    ul {
      line-height: 1;
      li {
        @include font-size(10);
        margin-right: 3px;
        padding-right: 19px;
        &:before {
          top: 20%;
          width: 10px;
          @include font-size(10);
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }
}
// keyvisual
.keyvisual {
  z-index: 2;
  position: relative;
  padding-top: 3px;
  text-align: center;
  &-ttl {
    text-align: center;
    display: inline-block;
    min-height: 86px;
    position: relative;
    padding: 6px 25px 0 30px;
    &:after {
      position: absolute; content: "";
      left: 0; top: 0;
      text-align: center;
      width: 86px; height: 86px;
      background: $yellow02;
      @include border-radius(50%);
    }
    .logo {
      text-align: center;
      margin-bottom: 10px;
      display: block;
    }
    .ttl-ja {
      display: block;
      @include font(16,24,150,bold);
      position: relative;
      z-index: 29;
    }
    .ttl-en {
      display: block;
      @include ffBun;
      @include font(36,42,150,400);
      color: $orange01;
      margin-top: 6px;
      position: relative;
      z-index: 29;
    }
  }
  &.smaller01 {
    .ttl-ja {
      @include font(14,20);
    }
  }
  @include SP {
    padding-top: 3px;
    &-ttl {
      min-height: 52px;
      padding: 0 18px 0 20px;
      &:after {
        width: 50px; height: 50px;
      }
      .logo {
        width: 110px;
        margin: 0 auto 8px;
      }
      .ttl-ja {
        @include font(14,20);
      }
      .ttl-en {
        @include font(24,32);
        margin-top: 0;
      }
    }
    &.smaller01 {
      .ttl-ja {
        @include font(10,15);
      }
    }
  }
}

/*-----------------------------------------------------------
TABLE
-------------------------------------------------------------*/


/*-----------------------------------------------------------
COMMON BLOCK
-------------------------------------------------------------*/
.cm-bg-pink01 {
  background: $pink01;
  padding: 0 0 43px;
  .wcm {
    position: relative;
    z-index: 99;
  }
  &:before {
    position: absolute;
    content: "";
    width: 170%;
    height: 500px;
    border-radius: 50%;
    background: $pink01;
    top: -148px;
    left: -35%;
    right: 0;
  }
  @include SP {
    padding: 0 0 43px;
    &:before {
      width: 180%;
      height: 175px;
      top: -113px;
      left: -40%;
    }
  }
}

.mySwiper {
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background: #F2EADF;
    opacity: 1;
    margin: 0 3px;
    &.swiper-pagination-bullet-active {
      background: #F86852;
    }
  }
  .swiper-slide {
    @include PC {
      padding: 0 30px;
      @include max-screen(1220px) {
        padding: 0 20px;
      }
      @include max-screen(1024px) {
        padding: 0 15px;
      }
    }
  }
  .swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 46px;
  }
  .swiper-button-prev, .swiper-button-next {
    width: 62px; height: 62px;
    margin-top: -64px;
    @include add_prefix(transition, 0.3s ease all);
    &:after {
      display: none;
      content: "";
    }
    @include HOVER {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .swiper-button-prev {
    background: url($img_url+'common/icon/icn_prev.svg') no-repeat center center;
    left: -130px;
    @include PC {
      @include max-screen(1220px) {
        left: auto;
        right: 92%;
      }
    }
  }
  .swiper-button-next {
    background: url($img_url+'common/icon/icn_next.svg') no-repeat center center;
    right: -130px;
    @include PC {
      @include max-screen(1220px) {
        right: auto;
        left: 92%;
      }
    }
  }
  @include SP {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 3px;
    }
    .swiper-pagination {
      margin-top: 22px;
    }
    .swiper-button-prev, .swiper-button-next {
      width: 27px; height: 27px;
      margin-top: -38px;
    }
    .swiper-button-prev {
      background-size: 100% 100%;
      left: 10px;
    }
    .swiper-button-next {
      background-size: 100% 100%;
      right: 10px;
    }
  }
}

.cm-js-slider01 {
  position: relative;
  padding: 0 30px;
  &.is-center {
    .swiper-wrapper {
      justify-content: center;
    }
  }
  .mySwiper {
    overflow: hidden;
  }
  .swiper-slide {
    padding: 0 11px;
    @include PC {
      @include max-screen(890px) {
        padding: 0 6px;
      }
    }
  }
  .swiper-button-prev, .swiper-button-next {
    width: 44px; height: 44px;
    top: 28.2%; margin-top: 0;
    @include add_prefix(transition, 0.3s ease all);
    &:after {
      display: none;
      content: "";
    }
    @include HOVER {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .swiper-button-prev {
    background: url($img_url+'common/icon/icn_prev02.svg') no-repeat center center;
    left: -25px;
    @include PC {
      @include max-screen(1320px) {
        left: -13px;
      }
    }
  }
  .swiper-button-next {
    background: url($img_url+'common/icon/icn_next02.svg') no-repeat center center;
    right: -25px;
    @include PC {
      @include max-screen(1320px) {
        right: -13px;
      }
    }
  }
  @include SP {
    padding: 0px;
    .mySwiper {
      padding: 0 10%;
    }
    .swiper-slide {
      padding: 0 12px;
    }
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
    .swiper-button-prev {
      left: 5px;
    }
    .swiper-button-next {
      right: 5px;
    }
  }
}

.cm-list-class {
  .item {
    padding-bottom: 20px;
    a {
      display: block;
      background: #fff;
      @include border-radius(15px);
      box-shadow: 0 4px 10px rgba(0,0,0,0.05);
      @include add_prefix(transition, 0.3s ease all);
      position: relative;
      .tag-end {
        color: #fff;
        position: absolute;
        left: 18px;
        top: 18px;
        @include font(14,20,100,bold);
        text-align: center;
        width: 100px;
        padding: 8px 3px 9px;
        z-index: 99;
        @include SP {
          @include font(12,20,100,bold);
          width: 80px;
          padding: 3px 3px 3px;
          left: 12px;
          top: 12px;
        }
        &.recruit {
          background: #F86852;
        }
        &.event {
          background: #726967;
        }
      }
      @include HOVER {
        &:hover {
          opacity: 1;
          @include transform(translateY(-10px));
        }
      }
    }
    &-thumb {
      @include border-radius(15px 15px 0 0);
    }
    .js-image {
      // @include aspect-ratio(280, 186);
      @include aspect-ratio(1390, 780);
      @include border-radius(15px 15px 0 0);
    }
    &-ttl {
      @include font(18,28,0,bold);
      margin-bottom: 13px;
      text-overflow: ellipsis;
      overflow : hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
    &-meta {
      @include flexbox;
      @include align-items(flex-start);
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      margin-bottom: 13px;
      .mt-txt01 {
        color: $gray02;
        @include font(15,22,0,bold);
        margin-top: 2px;
        width: calc(100% - 110px);
      }
      .mt-txt02 {
        background: $yellow01;
        @include font(12,18,100);
        @include border-radius(30px);
        text-align: center;
        width: 105px;
        padding: 3px 12px;
      }
    }
    &-except {
      @include font(14,26,0,400);
      margin-bottom: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    &-date {
      @include ffRu;
      @include font(15,18,0,bold);
      margin-bottom: 17px;
    }
    &-info {
      @include border-radius(0 0 15px 15px);
      padding: 14px 18px 18px;
    }
    &-btn {
      text-align: center;
      .cm-btn02 {
        margin: 0 auto;
      }
    }
  }
  @include SP {
    .item {
      padding-bottom: 20px;
      &-thumb {
      }
      .js-image {
      }
      &-ttl {
        @include font(15,24);
        margin-bottom: 8px;
      }
      &-meta {
        margin-bottom: 13px;
        .mt-txt01 {
          @include font(13,19);
          margin-top: 1px;
          width: calc(100% - 100px);
        }
        .mt-txt02 {
          @include font(11,17);
          text-align: center;
          width: 93px;
          padding: 3px 2px;
        }
      }
      &-except {
        @include font(12,18);
        margin-bottom: 10px;
      }
      &-date {
        @include font(13,15,0);
        margin-bottom: 15px;
      }
      &-info {
        padding: 8px 10px 13px;
      }
      &-btn {
        padding: 0 10px;
      }
    }
  }
}

.cm-news-pr{
  display: inline-block;
  color: #555;
  @include font-size(11);
  border: 1px solid #555;
  border-radius: 4px;
  padding: 3px 7px;
  line-height: 1;
  background-color: #fff;
  @include SP{
      @include font-size(10);
      padding: 2px 4px;
  }
}
.cm-news-cats {
  display: inline-block;
  vertical-align: top;
  @include ffRu;
  @include font(12,14,150,bold);
  @include PC {
    @include max-screen(1040px) {
      @include font(11,14,100);
    }
  }
  span {
    display: inline-block;
    vertical-align: top;
    padding: 6px 10px;
    min-width: 123px;
    color: #fff;
    text-align: center;
    @include border-radius(20px);
    @include PC {
      @include max-screen(1040px) {
        span {
          min-width: 120px;
        }
      }
    }
    &.c-diet {
      background: #F56850;
    }
    &.c-fitness {
      background: #006CA8;
    }
    &.c-healthcare {
      background: #E5B506;
    }
    &.c-beauty {
      background: #FBB5C1;
    }
    &.c-lifestyle {
      background: #0A5127;
    }
  }
  @include SP {
    @include font(8,9);
    span {
      padding: 2px 2px;
      min-width: 74px;
    }
  }
}

.cm-news-tag{
  display: inline-block;
  padding: 5px 20px;
  @include font-size(14);
  border-radius: 5px;
  line-height: 1.43;
  background: #fff;
  font-weight: 500;
  &::before{
      content: '#';
      display: inline-block;
  }
  @include SP{
      padding: 4px 10px;
      @include font-size(10);
  }
}
.cm-news-taglist{
  display: flex;
  flex-wrap: wrap;
  >li{
      margin-bottom: 5px;
      &:not(:last-child){
          margin-right: 10px;
      }
      @include SP{
          margin-bottom: 2px;
          &:not(:last-child){
              margin-right: 5px;
          }
      }
  }
}
.cm-news-pickupTag{
  margin-top: 40px;
  @include SP{
      margin-top: 15px;
  }
  .cm-news-pickupTag-ttl{
      text-align: center;
      @include font-size(18);
      color: $orange01;
      line-height: 1.44;
      font-weight: 900;
      @include SP{
          @include font-size(15);
      }
      &::before,
      &::after{
          content: '-';
          display: inline-block;
          margin: 0 5px;
      }
  }
  .cm-news-taglist{
      justify-content: center;
      margin-top: 15px;
      @include SP{
          margin-top: 5px;
      }
      .cm-news-tag{
          @include SP{
              @include font-size(10);
          }
      }
  }
}

.cm-list-report {
  @include flexbox;
  @include flex-wrap(wrap);
  .item {
    padding-bottom: 20px;
    a {
      display: block;
      @include add_prefix(transition, 0.3s ease all);
    }
    &-thumb {
      margin-bottom: 14px;
    }
    .js-image {
      @include aspect-ratio(320, 200);
    }
    &-ttl {
      @include font(18,28,0,bold);
      margin-bottom: 13px;
    }
    &-except {
      @include font(14,26,0,400);
      margin-bottom: 11px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    &-date {
      @include ffRu;
      @include font(15,18,0,bold);
    }
  }
  @include PC {
    .item {
      width: 30.77%;
      margin: 0 3.845% 25px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  @include SP {
    .item {
      width: 46%;
      margin: 0 8% 30px 0;
      padding-bottom: 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &-thumb {
        margin-bottom: 8px;
      }
      .js-image {
        @include aspect-ratio(160, 101);
      }
      &-ttl {
        @include font(13,21);
        margin-bottom: 13px;
      }
      &-except {
        display: none;
        @include font(13,21);
      }
      &-date {
        @include font(10,12);
      }
    }
  }
}

.cm-box-search {
  width: 171px;
  input {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    font: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    @include border-radius(50px);
    padding: 11px 40px 13px 30px;
    text-align: center;
    width: 100%;
    position: relative;
    @include ffNS;
    @include font(12,16,100,500);
    background: $yellow01 url($img_url+'common/icon/icn_zoom.svg') no-repeat right 16px top 6px;
    &::-webkit-input-placeholder { /* Edge */
      color: #B5A7A7;
      @include ffRu;
      @include font(12,16,300,500);
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #B5A7A7;
      @include ffRu;
      @include font(12,16,300,500);
    }
    &::placeholder {
      color: #B5A7A7;
      @include ffRu;
      @include font(12,16,300,500);
    }
    &:focus {
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-ms-input-placeholder {
        color: transparent;
      }
      &::placeholder {
        color: transparent;
      }
    }
  }
}
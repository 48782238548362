.login_container{
  .sml-user-links-title{
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.login, .sml-login{
  max-width: 320px;
  width: 100%;

  p{
    margin-bottom: 20px !important;
  }

  form[name="loginform"]{
    @include clearfix();

    p{
      margin-bottom: 0;
    }
    p:not([class]) {
      margin-bottom: 16px;
    }
    label[for="user_login"]
    , label[for="user_pass"]
    {
      display: block;
      width: 100%;
    }
    .input{
      display: block;
      width: 100%;
      font-size: 20px;
      width: 100%;
      padding: 5px;
      margin: 2px 6px 0px 0;
      background: #fbfbfb;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 5px;
      color: #32373c;
      transition: 50ms border-color ease-in-out;
      outline: none;
    }
    .forgetmenot{
      float: left;
      padding-top: 5px;
      input{

      }
      label{

      }
    }
    .submit{
      float: left;
      input[type="submit"]{
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        line-height: 28px;
        padding: 3px 12px 2px;
        min-width: 120px;
        border-radius: 5px;
        background: $orange01;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }

  .sml-action-links
  , .sml-user-links
  {
    margin: 20px 0 0;
    padding-left: 0;
    @include font-size(14);
    line-height: 1;
    li{
      list-style: none;
      position: relative;

      span{
        display: block;
        color: $orange01;
        font-weight: bold;
        @include font-size(16);
      }

      &:nth-child(1){
        span{

        }
        a{
          background-color: transparent;
          line-height: 28px;
          padding: 3px 12px 4px;
          max-width: 240px;
          width: 100%;
          border-radius: 5px;
          background: #F86851;
          color: #fff;
          font-weight: bold;
          @include font-size(16);
          text-align: center;
          display: block;
          margin: 15px 0 0;
        }
      }

      &:nth-child(2){
        margin-top: 30px;
        span{

        }
        a{
          padding-left: 16px;
          display: inline-block;
          position: relative;
          &:nth-child(n + 2){
            margin-top: 10px;
          }
          &:before {
            content: '';
            position: absolute;
            top: .25em;
            left: 0;
            transform: rotate(45deg);
            width: 6px;
            height: 6px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@charset 'UTF-8';

#footer {
  background: #F2EADF;
  padding: 80px 0 85px;
  .footerInner {
    .f-logo {
      width: 200px;
      padding-top: 5px;
      @include PC {
        @include max-screen(890px) {
          width: 135px;
        }
      }
    }
    .f-menu {
      width: calc(100% - 200px);
      max-width: 760px;
      @include PC {
        @include max-screen(890px) {
          width: calc(100% - 155px);
        }
      }
      ul {
        margin-bottom: 5px;
        @include PC {
          @include max-screen(890px) {
            margin-bottom: 0;
          }
        }
        li {
          display: inline-block;
          vertical-align: top;
          &:first-child {
            a {
              &:before {
                display: none;
              }
            }
          }
          a {
            @include font(14,32,0,500);
            display: inline-block; vertical-align: top;
            position: relative;
            @include PC {
              @include max-screen(990px) {
                @include font(13,32);
              }
            }
            &:before {
              content: "｜";
              left: 0; display: inline-block; vertical-align: top;
              @include font(14,32,0,500);
              margin: 0 12px;
              @include PC {
                @include max-screen(1100px) {
                  margin: 0 5px;
                }
                @include max-screen(990px) {
                  margin: 0 3px;
                  @include font(13,32);
                }
              }
            }
            @include HOVER {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    @include PC {
      @include flexbox;
      @include flex-wrap(wrap);
      @include justify-content(space-between);
    }
  }
  @include SP {
    padding: 51px 0 20px;
    .footerInner {
      .f-logo {
        text-align: center;
        width: 100%;
        padding-top: 0;
        margin-bottom: 18px;
        img {
          width: 150px;
        }
      }
      .f-sns {
        margin-bottom: 53px;
        text-align: center;
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0 8px;
          img {
            height: 28px;
          }
          &.icn_twitter {
            img {
              height: 25px;
            }
          }
        }
      }
      .f-menu {
        width: 100%;
        max-width: 100%;
        ul {
          margin-bottom: -1px;
          font-size: 0;
          li {
            a {
              @include font(11,26);
              &:before {
                @include font(11,26);
                margin: 0;
              }
            }
            &.first-sp {
              a {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .cp-right {
      text-align: center;
      margin-top: 35px;
      @include ffRu;
      @include font(12,20,0,600);
    }
  }
}